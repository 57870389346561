'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:TemplateUtils

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'TemplateUtils', [
    '$log'
    '$http'
    ($log, $http) ->
      TemplateUtilsBase = {}
      TemplateUtilsBase.getMundoDataTableTemplateUrl = ->
        $http.get('mundo-components/mundo-data-table/views/mundo-data-table.tpl.html')
          .then (response) ->
            response.data

      TemplateUtilsBase.getMundoDataDetailTemplateUrlString = ->
        'mundo-components/mundo-data-table/views/mundo-data-detail.tpl.html'

      TemplateUtilsBase.getMundoDataDetailModalTemplateUrlString = ->
        'mundo-components/mundo-data-table/views/mundo-data-detail-modal.tpl.html'

      TemplateUtilsBase
  ]
